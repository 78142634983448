// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-workshop-js": () => import("./../src/templates/workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clases-y-talleres-js": () => import("./../src/pages/clases-y-talleres.js" /* webpackChunkName: "component---src-pages-clases-y-talleres-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publicaciones-js": () => import("./../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */),
  "component---src-pages-servicios-biodanza-perinatal-js": () => import("./../src/pages/servicios/biodanza-perinatal.js" /* webpackChunkName: "component---src-pages-servicios-biodanza-perinatal-js" */),
  "component---src-pages-servicios-biodanza-js": () => import("./../src/pages/servicios/biodanza.js" /* webpackChunkName: "component---src-pages-servicios-biodanza-js" */),
  "component---src-pages-servicios-parto-y-movimiento-js": () => import("./../src/pages/servicios/parto-y-movimiento.js" /* webpackChunkName: "component---src-pages-servicios-parto-y-movimiento-js" */),
  "component---src-pages-servicios-terapia-bioenergetica-js": () => import("./../src/pages/servicios/terapia-bioenergetica.js" /* webpackChunkName: "component---src-pages-servicios-terapia-bioenergetica-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-talleres-js": () => import("./../src/pages/talleres.js" /* webpackChunkName: "component---src-pages-talleres-js" */),
  "component---src-pages-testimonios-js": () => import("./../src/pages/testimonios.js" /* webpackChunkName: "component---src-pages-testimonios-js" */),
  "component---src-pages-uma-js": () => import("./../src/pages/uma.js" /* webpackChunkName: "component---src-pages-uma-js" */)
}

